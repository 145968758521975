
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { State, Action, Getter, Mutation } from 'vuex-class';
import DefaultLayout from '@/components/DefaultLayout.vue';
import J1lSearchBox from '@/components/J1lSearchBox.vue';
import { Orderline, SearchRequestModel } from '@/store/models/OrderlineModel';
import { Invoice, InvoiceFilterType, InvoiceLine } from '@/store/models/InvoiceModel';
import { OrdersService } from '@/services/orders-service';
import { CustomerService } from '@/services/customer-service';
import { GetParentElementByClassName } from '@/helpers/HtmlHelper';
import { StringHelper } from '@/helpers/StringHelper';
import { SetSnackBar } from '@/helpers/SnackbarHelper';
import NoInvoices from '@/components/NoInvoices.vue';
import { InvoiceService } from '@/services/invoice-service';
import InvoiceCard from '@/components/cardComponents/InvoiceCard.vue';
import OrderInvoiceDialog from '@/components/OrderInvoiceDialog.vue';
import OrderPacklistDialog from '@/components/OrderPacklistDialog.vue';
import PayInvoiceDialog from '@/components/PayInvoiceDialog.vue';
import MultipleInvoiceHandlerDialog from '@/components/MultipleInvoiceHandlerDialog.vue';

@Component({
  components: {
    DefaultLayout,
    J1lSearchBox,
    NoInvoices,
    InvoiceCard,
    OrderInvoiceDialog,
    OrderPacklistDialog,
    PayInvoiceDialog,
    MultipleInvoiceHandlerDialog
  }
})
export default class extends Vue {
  $refs!: {
    DefaultActions: HTMLFormElement;
    OrderInvoiceDialog: HTMLFormElement;
    OrderPacklistDialog: HTMLFormElement;
    invoiceSearchInput: HTMLFontElement;
    PayInvoiceDialog: HTMLFormElement;
    MultipleInvoiceHandlerDialog: HTMLFormElement;
  };
  $router: any;
  /* Properties */
  @Getter('getFilteringType', { namespace: 'invoices' })
  filteringType!: InvoiceFilterType;
  @Getter('getLoadingState', { namespace: 'invoices' })
  areInvoicesLoading: any;
  @Getter('getHasInvoices', { namespace: 'invoices' })
  hasInvoices: any;
  @Getter('invoices', { namespace: 'invoices' })
  invoices!: Invoice[];
  @Getter('filteredInvoices', { namespace: 'invoices' })
  filteredInvoices!: Invoice[];
  @Mutation('setHasInvoices', { namespace: 'invoices' })
  setHasInvoices: any;
  @Mutation('setFilteringType', { namespace: 'invoices' })
  setFilteringType: any;
  @Mutation('setInvoices', { namespace: 'invoices' })
  setInvoices: any;
  @Mutation('setInvoicesLoading', { namespace: 'invoices' })
  setInvoicesAreLoading: any;
  /* Store Actions */
  @Watch('invoices')
  onInvoicesChange(value: any) {
    this.invoicesForDisplay = this.invoices;
  }
  @Watch('filteredInvoices')
  onFilteredInvoicesChange(value: any) {
    this.invoicesForDisplay = this.filteredInvoices;
  }
  @Watch('searchedOrderlines')
  onSearchedOrderlines(val: any) {
    if (val[0]) {
      this.handleChipSelection(0);
      this.activeChip = 0;
      //this.OrderlinesForDisplay = BuildOrderlineArrayForDisplay(val);
      this.searchLoading = false;
    }
    // this.searchItems = this.getSearchSuggestions();
  }
  @Watch('searchText')
  onSearchTextChange(val: string) {
    val && val !== this.selectedSuggestion && this.getSuggestions();
  }
  @Watch('selectedSuggestion')
  onSelectedSuggestionChange(val: any) {
    if (val) {
      this.getFilteredInvoices(val.PartNumber);
      this.$refs.invoiceSearchInput.blur();
    } else {
      this.invoicesForDisplay = this.invoices;
    }
  }
  /* Data */
  itemsSearch: any[] = [
    {
      headline: ['PartNumber', 'Description'],
      title: 'Items'
    },
    {
      headline: [],
      title: 'Order'
    }
  ];
  currentFilter: any = {
    id: 0,
    text: '<strong>Paid</strong> and <strong>Unpaid</strong> Invoices',
    count: 0,
    isSelected: true,
    isLoading: false,
    shouldShow: true
  };
  filterOptions: Array<{
    id: number;
    text: string;
    count: number;
    isSelected: boolean;
    isLoading: boolean;
    shouldShow: boolean;
  }> = [
    {
      id: 0,
      text: '<strong>Paid</strong> and <strong>Unpaid</strong> Invoices',
      count: 0,
      isSelected: true,
      isLoading: false,
      shouldShow: true
    },
    {
      id: 1,
      text: '<strong>Unpaid</strong> Invoices',
      count: 0,
      isSelected: false,
      isLoading: false,
      shouldShow: true
    }
  ];
  searchBy: number = 0;
  searchItems: any = [];
  searchText: string = 'PartNumber';
  searchValue: string = '';
  searchModel: SearchRequestModel | null = null;
  selectedPO: string = '';
  stopPagination: boolean = false;
  searchLoading: boolean = false;
  areChipsGrabbingData: boolean = false;
  activeChip: number = 0;
  suggestions: any[] = [];
  selectedSuggestion: any = null;
  isLoading: boolean = false;
  typingTimer: any;
  doneTypingInterval: number = 250;
  currentInvoice: Invoice = new Invoice();
  invoicesForDisplay: Invoice[] = [];
  paymentProfileId!: string;
  paginationModel: any = {
    Take: 5,
    Skip: 0,
    Query: '',
    ShouldShowUnpaidInvoicesOnly: false,
    ShouldLoad: true,
    CurrentPage: 1
  };
  currentInvoiceId: string | null = null;
  /* Utility Functions */
  calculateFee(fee: number): string {
    return fee ? fee.toFixed(2) : '0.00';
  }
  /* Methods */
  async getInvoices(shouldForceUpdate: boolean = false) {
    this.isLoading = true;
    try {
      if (!this.hasInvoices || shouldForceUpdate) {
        this.paginationModel.ShouldLoad = true;
        this.paginationModel.ShouldShowUnpaidInvoicesOnly = this.currentFilter.id === 1;
        this.$store.dispatch('invoices/GetInvoices', this.paginationModel);
      } else {
        this.invoicesForDisplay = this.invoices;
      }
    } catch (err) {
      console.log(err);
      SetSnackBar(
        'There was a problem getting your invoices, please contact technical support at techsupport@just1label.com'
      );
    }
    this.isLoading = false;
  }
  async getInvoiceLines(invoice: Invoice) {
    invoice.IsLoading = true;
    try {
      const { data } = await InvoiceService.GetInvoiceLines(invoice.Id);
      invoice.InvoiceLines = data.map(d => new InvoiceLine(d));
    } catch (err) {
      SetSnackBar('Error loading all invoice lines');
    }
    invoice.IsLoading = false;
  }
  searchBySearchSuggestion(searchSuggestion: any) {
    if (!searchSuggestion) return;
    let searchValue = searchSuggestion;
    if (searchSuggestion.PartNumber) searchValue = searchSuggestion.PartNumber;
    if (searchSuggestion.PO) searchValue = [searchSuggestion.PO];
    let typeValue = -1;
    if (searchSuggestion.PartNumber) typeValue = 0;
    if (searchSuggestion.PO) typeValue = 1;
    if (searchSuggestion)
      this.searchBySearchValue({
        value: searchValue,
        isEnter: true,
        TypeValue: typeValue
      });
  }
  removeRedBorder() {
    let input = document.getElementById('searchBox');
    if (input) {
      let parent = GetParentElementByClassName(input, 'v-input');
      if (parent) parent.style.border = null;
    }
  }
  async getSuggestions() {
    this.isLoading = true;
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(async () => {
      try {
        let model = {
          Take: 10,
          Skip: 0,
          Query: !this.searchText ? this.searchText : this.searchText.trim(),
          FilterOptions: this.currentFilter.id
        };
        // this.$store.dispatch('invoices/GetFilteredInvoices', model);
        const response = await InvoiceService.GetAutocomleteSuggestionsBySearchQuery(
          model
        );
        this.suggestions = [];
        this.suggestions = this.formatSuggestions(response.data);
      } catch (err) {
        console.log(err);
      }
      this.isLoading = false;
    }, this.doneTypingInterval);
  }
  async getFilteredInvoices(searchQuery: string) {
    try {
      let model = {
        Take: 10,
        Skip: 0,
        Query: !searchQuery ? searchQuery : searchQuery.trim(),
        FilterOptions: this.currentFilter.id
      };
      this.$store.dispatch('invoices/GetFilteredInvoices', model);
    } catch (err) {
      console.log(err);
    }
  }
  formatSuggestions(suggestionList: any[]) {
    if (!suggestionList[0]) suggestionList[0] = { NoItemFound: true };
    let list = [{ header: 'Invoices' }, ...suggestionList];
    return list;
  }
  doesMatch(firstItem: string, secondItem: string) {
    return StringHelper.doesFirstStringContainSecond(firstItem, secondItem);
  }
  formatMatchedString(stringToChange: string) {
    if (!this.searchText) return stringToChange;
    let location = stringToChange
      .toLowerCase()
      .search(this.searchText.trim().toLowerCase());
    let stringStart = stringToChange.substring(0, location);
    let stringEnd = stringToChange.substring(
      location + this.searchText.trim().length,
      9999
    );
    let stringMatch = stringToChange.substring(
      location,
      location + this.searchText.trim().length
    );
    return `${stringStart}<span class="roboto-bold">${stringMatch}</span>${stringEnd}`;
  }
  backspacePressed() {
    this.$refs.invoiceSearchInput.blur();
  }
  search() {
    if (this.selectedSuggestion) return;
    if (this.searchText) this.suggestions.push({ PartNumber: this.searchText });
    this.selectedSuggestion = this.suggestions[this.suggestions.length - 1];
    if (!this.searchText || this.searchText.length < 3) {
      this.currentFilter = this.filterOptions[0];
      this.clearSearch();
    } else {
      this.refreshPagination();
    }
  }
  searchByPartNumber(partnumber: string) {
    this.searchText = partnumber;
    this.search();
  }
  async handleChipSelection(chipId: number) {
    if (chipId != 0 && this.searchModel) {
      this.clearSearch();
    }
    this.currentFilter = this.filterOptions[chipId];
    this.areChipsGrabbingData = true;
    this.searchLoading = true;
    this.stopPagination = chipId > 0;
    this.invoicesForDisplay = [];
    this.areChipsGrabbingData = false;
    this.searchLoading = false;
  }
  clearSearch() {
    this.selectedSuggestion = null;
    this.searchBySearchValue(null);
  }

  async getFilteredOrderlines(response: any) {
    this.$store.commit('orderlines/setCurrentPage', 1);
    this.refreshPagination();
    this.$store.dispatch('orderlines/ClearSearchOrder');
    this.currentFilter = this.filterOptions[0];
    this.searchModel = new SearchRequestModel({
      SearchValue: response.value[0] ? response.value : response.value.trim(),
      TypeValue: response.TypeValue
    });
  }

  clearFilteredInvoices() {
    this.$store.dispatch('invoices/ClearSearchInvoices');
    this.searchModel = null;
  }

  clearFilteredOrderlines(
    isFromParent: boolean = false,
    shouldBuildOrderlineDisplay: boolean = true
  ) {
    this.$store.dispatch('orderlines/ClearSearchOrder');
    //this.OrderlinesForDisplay = BuildOrderlineArrayForDisplay(this.orderlines);
    this.searchModel = null;
  }
  searchBySearchValue(response: any) {
    if (response && response.value) {
      this.getFilteredInvoices(response);
    } else {
      this.clearFilteredInvoices();
    }
  }
  async openPayDialog(invoice: any) {
    if (!this.paymentProfileId) {
      const response = await CustomerService.GetDefaultCustomerPaymentProfileId();
      this.paymentProfileId = response.data;
    }
    this.currentInvoice = invoice;
    this.$refs.PayInvoiceDialog.openDialog();
  }
  openInvoiceDialog(invoiceId: any) {
    this.currentInvoiceId = invoiceId;
    setTimeout(() => {
      this.$refs.OrderInvoiceDialog.openDialog();
    }, 100);
  }
  openPacklistDialog(po: string) {
    this.selectedPO = po;
    setTimeout(() => {
      this.$refs.OrderPacklistDialog.openDialog();
    }, 100);
  }
  refreshPagination() {
    this.stopPagination = true;
    setTimeout(() => {
      this.stopPagination = false;
      this.searchLoading = true;
    }, 1);
  }
  async infiniteScroll() {
    // End of the document reached?
    if (
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight - 200
    ) {
      this.updatePaginationModel();
    }
  }
  async updatePaginationModel() {
    if (
      this.paginationModel.CurrentPage * this.paginationModel.Take <=
      this.invoices.length
    ) {
      this.paginationModel.CurrentPage++;
      this.paginationModel.Skip =
        this.paginationModel.CurrentPage * this.paginationModel.Take -
        this.paginationModel.Take;
      await this.getInvoices(true);
    }
  }
  /* Loaders */
  /* Mounted */
  mounted() {
    window.addEventListener('scroll', this.infiniteScroll);
    this.getInvoices();
  }
  /* Created */
  /* Emmited Functions */
}
